import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import './cta.css';

const CTA = () => {
  return (
    <div className="cta-container" data-aos="fade-up">
      <div className="cta-content">
        <h2 data-aos="fade-up" data-aos-delay="100">Kontaktieren Sie uns</h2>
        <p data-aos="fade-up" data-aos-delay="150">
          Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten. Nutzen Sie die folgenden Kontaktmöglichkeiten:
        </p>
        <div className="cta-links" data-aos="fade-up" data-aos-delay="200">
          <div className="cta-row">
            <a href="mailto:webdesign@exabyte-systems.com" className="cta-link">
              <FaEnvelope className="cta-icon" /> webdesign@exabyte-systems.com
            </a>
            <a href="tel:015771842593" className="cta-link">
              <FaPhoneAlt className="cta-icon" /> +49 6621 4374980
            </a>
          </div>
          <div className="cta-row">
            <a href="/#contactus" className="cta-link">
              <FaMapMarkerAlt className="cta-icon" />Jetzt loslegen!
            </a>
            <a href="https://instagram.com/exabyte.systems" target="_blank" rel="noopener noreferrer" className="cta-link">
              <FaInstagram className="cta-icon" /> Folgen Sie uns auf Instagram
            </a>
          </div>
        </div>
        <div className="cta-footer" data-aos="fade-up" data-aos-delay="300">
          <a href="/impressum" className="footer-link">Impressum</a> | 
          <a href="/datenschutz" className="footer-link"> Datenschutz</a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
