import React from 'react';
import './Services.css';
import { FaCode, FaSearch, FaWrench, FaShoppingCart, FaPlug, FaServer } from 'react-icons/fa';

const Services = () => {
  return (
    <div className="container" id='services'>
      <div className="services">
        <h2 data-aos="fade-up">Unsere Dienstleistungen</h2>
        <div className="service-blocks">
          <div className="service-block" data-aos="fade-up" data-aos-delay="100">
            <FaCode className="service-icon" />
            <h5>Website-Design & -Entwicklung</h5>
            <p>Wir entwickeln Websites, die modern, funktional und auf Ihre Ziele ausgerichtet sind – für mehr Kunden und besseren Erfolg online.</p>
            <a href="/design" className="read-more">Mehr über Website-Design erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="200">
            <FaSearch className="service-icon" />
            <h5>Suchmaschinen-optimierung (SEO)</h5>
            <p>Mit unseren SEO-Strategien verbessern wir Ihre Sichtbarkeit und helfen Ihnen, mehr relevante Besucher zu erreichen.</p>
            <a href="/SEO" className="read-more">Mehr über SEO erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="300">
            <FaWrench className="service-icon" />
            <h5>Wartung & Support</h5>
<p>Wir kümmern uns um die Sicherheit Ihrer Website, sorgen für optimale Performance und halten Ihre Webseite immer auf dem neuesten Stand.</p>

      
            <a href="/support" className="read-more">Mehr über Wartung & Support erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="400">
            <FaShoppingCart className="service-icon" />
            <h5>E-Commerce-Lösungen</h5>
            <p>Wir erstellen leistungsstarke Online-Shops, die Ihre Verkäufe steigern und Ihren Kunden ein nahtloses Einkaufserlebnis bieten.</p>
            <a href="/ecommerce" className="read-more">Mehr über E-Commerce erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="500">
            <FaPlug className="service-icon" />
            <h5>Individuelle Webanwendungen</h5>
            <p>Wir entwickeln maßgeschneiderte Lösungen, die perfekt auf Ihre geschäftlichen Anforderungen abgestimmt sind.</p>
            <a href="/individual" className="read-more">Mehr über individuelle Webanwendungen erfahren</a>
          </div>
          <div className="service-block" data-aos="fade-up" data-aos-delay="600">
            <FaServer className="service-icon" />
            <h5>Webhosting & Domainverwaltung</h5>
            <p>Verlassen Sie sich auf sicheres Hosting und eine zuverlässige Verwaltung Ihrer Domain – für eine stets erreichbare Website.</p>
            <a href="/webhosting" className="read-more">Mehr über Webhosting erfahren</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
