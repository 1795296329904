import React from 'react';
import './aboutus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faUsers, faBullseye, faRocket } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {
  return (
    <div className="container" id="about">
      <div className="about-us">
        <h2>Wer Wir Sind</h2>
        <div className="about-grid">
          <div className="about-card" data-aos="fade-up">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faHistory} className="icon" />
            </div>
            <h3>Unsere Geschichte</h3>
            <p>
              Unsere Webagentur in Bad Hersfeld entwickelt <strong>individuelle Websites</strong> und <strong>maßgeschneiderte Weblösungen</strong>, um die <strong>Online-Präsenz</strong> unserer Kunden zu stärken. Seit unserer Gründung haben wir zahlreichen Unternehmen geholfen, ihre <strong>digitale Sichtbarkeit</strong> zu verbessern.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faUsers} className="icon" />
            </div>
            <h3>Unser Team</h3>
            <p>
              Unser erfahrenes Team kombiniert <strong>modernes Webdesign</strong> und <strong>SEO-Strategien</strong>, um <strong>benutzerfreundliche Websites</strong> zu erstellen. Wir arbeiten eng mit lokalen Unternehmen zusammen, um optimale Ergebnisse zu erzielen.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faBullseye} className="icon" />
            </div>
            <h3>Unsere Mission & Vision</h3>
            <p>
              Wir schaffen durch <strong>professionelle Weblösungen</strong> echten Mehrwert. Unsere Vision ist es, Unternehmen mit <strong>kreativem Webdesign</strong> und <strong>effektiven SEO-Maßnahmen</strong> langfristig erfolgreich zu machen.
            </p>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="300">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faRocket} className="icon" />
            </div>
            <h3>Warum Wir?</h3>
            <p>
              Wir bieten <strong>moderne, funktionale Websites</strong>, die genau auf Ihre Anforderungen zugeschnitten sind. Mit einem Standort in Bad Hersfeld kennen wir die Bedürfnisse lokaler Unternehmen und bieten maßgeschneiderte <strong>Webdesign- und SEO-Dienstleistungen</strong>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
